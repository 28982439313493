<template>
  <div class="notice">
    <van-nav-bar :title="$t('notice.通知')">
      <template #right>
        <!-- <img src="../../images/bgc/close.png"
             alt=""
             style="width:60px;height:22px;margin-top: 10px;"> -->
      </template>
    </van-nav-bar>
    <van-tabs v-model="activeName"
              @click="handleClick">
      <van-tab :title="item.title"
               :name="item.type"
               v-for="item in adTabsArr"
               :key="item.type">
        <div v-for="(items,index) in item.arr"
             :key="index">
          <h5>{{items.documentTitle}}</h5>
          <div>
            <span class="date">{{items.createTime}}</span>
            <p @click="linkNewsNotice(items)">{{ $t('notice.详情') }}</p>
          </div>
        </div>
        <p class="van-list__finished-text">{{ $t('notice.没有更多了') }}</p>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: '01',
      adTabsArr: [
        {
          title: '通知公告',
          type: '01',
          arr: [
            // {
            //   documentTitle: '周育先主持召开集团党风廉政建设和反腐败工作领导小组2020年第一次会议',
            //   createTime: '2020-6-12'
            // },
            // {
            //   documentTitle: '周育先赴山东部分企业调研指导工作',
            //   createTime: '2020-6-12'
            // },
            // {
            //   documentTitle: '周育先在线为清华经管EMBA授课：拓展全球化战略思维，赋能专',
            //   createTime: '2020-6-12'
            // },
            // {
            //   documentTitle: '周育先到部分改革试点企业调研改革工作',
            //   createTime: '2020-6-12'
            // },
            // {
            //   documentTitle: '中国建材集团连续入围“2020全球最有价值的50大工程和建设品牌”排行榜，稳居第16位',
            //   createTime: '2020-6-12'
            // },
          ]
        },
        {
          title: '政策法规',
          type: '06',
          arr: []
        },
        {
          title: '办事指南',
          type: '07',
          arr: []
        }
      ]
    }
  },
  methods: {
    //新闻公告、政策法规、办事指南
    getNewsData () {
      var params = {
        entity: {},
        'orderBy': 'id desc'
      };
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/SysDocument/list',
        data: params
      }).then(res => {
        this.adTabsArr[0].arr = res.data.rows.filter(item => item.documentType === '01');
        this.adTabsArr[1].arr = res.data.rows.filter(item => item.documentType === '06');
        this.adTabsArr[2].arr = res.data.rows.filter(item => item.documentType === '07');
      });
    },
    handleClick (name, title) {
      this.activeName = name;
    },
    // 判断当前新闻类型是否有跳转外部的链接
    linkNewsNotice (item) {
      if (item.externalLink) {
        this.linkToExternalLink(item.externalLink);
      } else {
        this.linkToDetail(item.id, item.documentType);
      }
    },
    // 跳转外部链接
    linkToExternalLink (url) {
      window.open(url);
    },
    linkToDetail (id, type) {
      this.$router.push({
        'name': 'notice/noticeDetail',
        'params': {
          'id': id,
          'viewType': 'home',
          'type': type
        },
        'query': {
          'id': id,
          'viewType': 'home',
          'type': type
        }
      });
    },
  },
  created () {
    //新闻公告政策法规
    this.getNewsData();
  }
}
</script>
<style>
.notice .van-tabs__wrap {
  position: fixed;
  width: 100%;
  top: 46px;
  z-index: 9;
}
.notice .van-tabs__content {
  padding-bottom: 50px;
  position: absolute;
  width: 100%;
  padding-top: 90px;
}
.notice .van-tabs__nav .van-tab {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.notice .van-tabs__nav .van-tab.van-tab--active {
  color: rgba(48, 130, 202, 1);
}
.notice .van-tabs__nav .van-tabs__line {
  background-color: rgba(67, 120, 190, 1);
  height: 2px;
}
.notice .van-tab__pane {
  padding: 10px 10px 0 10px;
}
.notice .van-tab__pane > div {
  height: 85px;
  padding: 9px;
  background: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.notice .van-tab__pane > div h5 {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin: 0;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 26px;
}
.notice .van-tab__pane > div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.notice .van-tab__pane > div div span {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.notice .van-tab__pane > div div p {
  padding: 0px 10px;
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 15px 15px 15px 15px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
</style>
